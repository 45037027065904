<template>
  <div class="map-page">
    <Header 
      @filter="isShowMapFilter=true"
      @list="isShowMapList=!isShowMapList"
      :icons="[{icon: FilterIcon, event: 'filter'}, {icon: ListIcon, event: 'list'}]"
    />
    <div class="tabs">
      <div class="tab" :class="{'active': tabIndex === 0 || tabIndex === 1}">
        <Popover v-model="showTermialModal" :actions="terminalList" @select="e => onSelectTermial(e, 0)">
          <template #reference><span @click="showTermialModal = true">终端网点</span></template>
        </Popover>
      </div>
      <div class="tab" :class="{'active': tabIndex === 2 || tabIndex === 3}">
        <Popover v-model="showPlantModal" :actions="terminalList" @select="e => onSelectTermial(e, 2)">
          <template #reference><span @click="showPlantModal = true">种植大户</span></template>
        </Popover>
      </div>
      <div class="tab" :class="{'active': tabIndex === 4 || tabIndex === 5}">
        <Popover v-model="showCustomerModal" :actions="terminalList" @select="e => onSelectTermial(e, 4)">
          <template #reference><span @click="showCustomerModal = true">潜在客户</span></template>
        </Popover>
      </div>
      <div class="tab" :class="{'active': tabIndex === 6 || tabIndex === 7}">
        <Popover v-model="showFieldModal" :actions="terminalList" @select="e => onSelectTermial(e, 6)">
          <template #reference><span @click="showFieldModal = true">示范田</span></template>
        </Popover>
      </div>
      <!-- <div class="tab" :class="{'active': tabIndex === 0}" @click="tabIndex = 0">终端网点</div> -->
      <!-- <div class="tab" :class="{'active': tabIndex === 1}" @click="tabIndex = 1">种植大户</div>
      <div class="tab" :class="{'active': tabIndex === 2}" @click="tabIndex = 2">潜在客户</div> -->
      <!-- <div class="tab" :class="{'active': tabIndex === 3}" @click="tabIndex = 3">示范田</div> -->
    </div>
    <!-- 地图 -->
    <div class="map-container" id="mapContainer"></div>

    <MapFilter :visible="isShowMapFilter" @confirm="handleFilter" />
    <MapList v-if="isShowMapList" :list="list" :type="tabIndex" />
  </div>
</template>

<script>
import { Popover } from 'vant';
import { fetchList } from '@/api/map';
import { initMap, getMapCenter, addMapMarker, clearAllMarkers } from '../../utils/map';
import Header from '@/components/header/headerWithIcon/index.vue';
import FilterIcon from '@/assets/images/common/filter-white.png';
import ListIcon from '@/assets/images/common/list.png';
import MapFilter from '@/components/select/map';
import MapList from './list';

export default {
  components: {
    MapFilter,
    Header,
    Popover,
    MapList,
  },
  data() { 
    return {
      pageNum: 1,
      pageSize: 5000,
      ListIcon,
      FilterIcon,
      tabIndex: 0,
      showTermialModal: false,
      showPlantModal: false,
      showCustomerModal: false,
      showFieldModal: false,
      isShowMapFilter: false,
      isShowMapList: false,
      terminalList: [
        { text: '史丹利' },
        { text: '自有' },
      ],
      list: [],
      filterParams: {},
    }
  },
  watch: {
    tabIndex() {
    },
  },
  async mounted() {
    // 初始化渠道类型
    if (this.$route.query.type) {
      this.tabIndex = parseInt(this.$route.query.type);
    }
    await this.initMap();
    this.getList();
  },
  computed: {
    type() {
      if (this.tabIndex === 0) {
        return 'kj_terminal_node';
      } else if (this.tabIndex === 1) {
        return 'dl_terminal_node';
      }  else if (this.tabIndex === 2) {
        return 'kj_bigfarmers';
      }  else if (this.tabIndex === 3) {
        return 'dl_bigfarmers';
      }  else if (this.tabIndex === 4) {
        return 'kj_potential_customers';
      } else if (this.tabIndex === 5) {
        return 'dl_potential_customers';
      } else if (this.tabIndex === 6) {
        return 'kj_model_field';
      } else if (this.tabIndex === 7) {
        return 'dl_model_field';
      }
      return 'kj_terminal_node';
    }
  },
  methods: {
    onSelectTermial(e, idx) {
      const subTab = e.text === '自有' ? 1 : 0;
      this.tabIndex = idx + subTab;
      this.getList();
    },

    /**
     * 筛选
     */
    handleFilter(params) {
      this.filterParams = params;
      this.isShowMapFilter = false;
      this.getList();
    },

    /**
     * 初始化地图
     */
    async initMap() {
      let res = null;
      if (this.$route.query.lat) {
        res = await initMap('mapContainer', {
          latitude: this.$route.query.lat,
          longitude: this.$route.query.lng,
        });
      } else {
        res = await initMap('mapContainer', { currentLocation: true, currentLocationMarker: true });
      }
      this.map = res.map;
    },

    /**
     * 获取渠道分布列表
     */
    async getList() {
      clearAllMarkers(this.map);
      this.list = [];
      const mapCenter = getMapCenter(this.map);
      const params = {
        // page: {
        //   pageNo: this.pageNum,
        //   pageSize: this.pageSize,
        //   orderBy: 'distance',
        // },
        'page.pageNo': this.pageNo,
        'page.pageSize': this.pageSize,
        'page.orderBy': 'distance',
        distance2: 9999,
        type: this.type,
        coordinate: `${mapCenter.lng},${mapCenter.lat}`,
        ...this.filterParams,
      };
      const res = await fetchList(params);
      this.list = res.data;
      res.data.forEach(item => {
        const {longitude = '', latitude = ''} = item;
        if (longitude && latitude) {
          addMapMarker({ map: this.map, latitude, longitude })
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.map-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .tabs {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    background-color: #fff;
    .tab {
      width: 81px;
      height: 29px;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      border: 1px solid transparent;
      text-align: center;
      color: #818181;
      &.active {
        color: #CD001F;
        border: 1px solid #CD001F;
      }
    }
  }
  .map-container {
    width: 100%;
    flex: 1;
  }
}
</style>
