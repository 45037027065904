<template>
  <div class="map-list">
    <div class="card" v-for="item in list" :key="item.id">
      <div class="name">{{item.name}}</div>
      <div class="row">
        <div class="label">联系电话</div>
        <div class="phone">{{item.mobile}}</div>
      </div>
      <div class="row">
        <div class="label">上次拜访时间</div>
        <div>{{item.lastTime}}</div>
      </div>
      <div class="row">
        <div class="label">拜访次数</div>
        <div>{{item.visitTimes1 || item.visitTimes2 || 0}}</div>
      </div>
      <div class="footer">
        <div class="distance">{{item.distance}}</div>
        <Button class="button" size="mini" @click="handleMapNavigation(item)">导航</Button>
        <div style="flex: 1" />
        <Button class="button check" size="mini" @click="navigateCheckPage(item.id)" v-if="!parseInt(type)">检核</Button>
        <Button class="button" size="mini" @click.stop="navigateInfoPage(item.id)">拜访</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapNavigation } from '@/utils/map';
import Button from '@/components/common/button/CustomButton'

export default {
  components: {
    Button,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '0',
    }
  },
  methods: {
    navigateCheckPage(id) {
      this.$router.push({
        name: "TerminalOutletsShidanliCheck",
        params: { id },
      });
    },
    navigateInfoPage(id) {
      if (parseInt(this.type) === 1) {
        this.$router.push({
          name: "BigGrowersShidanliInfo",
          params: { id },
        });
      } else if (parseInt(this.type) === 2) {
        this.$router.push({
          name: "potentialCustomersShidanliInfo",
          params: { id },
        });  
      } else {
        this.$router.push({
          name: "TerminalOutletsShidanliInfo",
          params: { id },
        });
      }
    },
    handleMapNavigation(item) {
      mapNavigation({
        latitude: item.latitude,
        longitude: item.longitude,
        title: item.name,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.map-list {
  min-height: calc(100vh - 88px);
  box-sizing: border-box;
  background-color: #FAFBFC;
  text-align: left;
  padding-bottom: 48px;
  .tabs {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    background-color: #fff;
    .tab {
      width: 81px;
      height: 29px;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      border: 1px solid transparent;
      text-align: center;
      color: #818181;
      &.active {
        color: #CD001F;
        border: 1px solid #CD001F;
      }
    }
  }
  .card {
    margin: 12px auto 0;
    width: 351px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    padding: 15px 12px 10px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    color: #2C2C2C;
    .name {
      font-weight: 500;
      padding-bottom: 5px;
    }
    .row {
      margin-top: 6px;
      display: flex;
      align-items: center;
    }
    .label {
      font-weight: 400;
      color: #818181;
      margin-right: 8px;
    }
    .phone {
      color: #006EFB;
    }
    .footer {
      border-top: 1px solid #EAEAEA;
      margin-top: 9px;
      padding-top: 9px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #2C2C2C;
      .distance {
        margin-right: 6px;
        position: relative;
        padding-left: 8px;
        &:before {
          content: ' ';
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #CD001F;
          position: absolute;
          left: 0;
          top: 7px;
        }
      }
      .button {
        margin-left: 6px;
      }
    }
  }
}
</style>
